import React, { Component } from 'react';
import '../main.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'


class Forms extends Component{
    render(){
        return(
            <div className="container-fluid">
              <div className="mt-3">
                <form>
                    <div className="row">
                     <div className="col-md-6 col-sm-6">
                      <div className="list-inline">
                          <div className="list-inline-item gray-shadow">
                          <select>
                          <option>Weekly</option>
                          <option>Monthly</option>
                          <option>New</option>
                          </select>
                          </div>
                          <div className="list-inline-item">
                          <label className="period-color">Period</label>
                          <span>
                          <select className="custom-select">
                               <option>hii</option>
                               <option className="bg-red">www</option>
                           </select>
                           </span>
                         </div>
                      </div>
                   </div>
                   <div className="col-md-6 col-sm-6 text-right">
                   <div className="dis-inline-block">
                     <div className="input-group search-input">
                           <input type="text" className="form-control custom-serach-input"  placeholder="Order no" />
                           <div className="input-group-append" style={{border: "1px solid red!important"}}>
                              <button className="btn btn-default line-height-none" type="button">
                              <FontAwesomeIcon icon={faSearch} />
                              </button>
                           </div>
                     </div>
                    </div>
                    <div className="dis-inline-block">
                            <button className="btn btn-submit">Submit</button>
                    </div>
                </div>
                </div>
               </form>
               </div>
            </div>

        )
    }
}

export default Forms
