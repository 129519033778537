import React, { Component } from 'react';
import '../App.css'

function lendernotfound(props)
{

    const logoutHandler =() =>{
        console.log("*****")    
        localStorage.clear()
        props.history.push('/');
      }
      
    return (
            <div className="not_found_parent">
            <div className="not_found_child">
                <h1>Lender not mapped with client app!!!</h1>
                <a onClick={logoutHandler} style={{fontSize : "25px"}}>Back to Login</a>
            </div>
            </div>
        )
}
export default lendernotfound;
