import $ from 'jquery';

class CommonFunction {
  dateFormate = (rdate) => {
    if (rdate !== null && rdate !== "") {
      var date = new Date(rdate);      
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      var convertDate = day + '/' + month + '/' + year      
      return convertDate
    } else {
      return 'NA'
    }
  }

  tableAddressEllipsis = (value, length) => {
    if (value.length > length && value !== undefined) {
      return value.substring(0, length) + "..."
    } else {
      return value
    }
  }

  tableDateFormate = (rdate) => {
    if (rdate !== null && rdate !== "" && rdate !== "N/A") {

      var date = new Date(rdate);
      var year = date.getFullYear();
      var month = date.getMonth();
      var months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"][month]
      var day = date.getDate();
      const nth = (() => {
        const day = date.getDate();
        if (day >= 11 && day <= 13) {
          return "th"
        } else {
          switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
          }
        }
      })()
      if (day < 10) {
        day = '0' + day;
      }
      var convertDate = day + nth + ' ' + months + ' ' + year
      return convertDate
    } else {
      return 'NA'
    }
  }

  tableDateFormate_indus = (rdate) => {
    if (rdate !== null && rdate !== "" && rdate !== "N/A") {
      var date = new Date(rdate);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      var convertDate = day + '/' + month + '/' + year
      return convertDate
    } else {
      return 'NA'
    }
  }

  manage_vehicle_dd() {
    var count = 0
    $(".dropdown_parent input[type='checkbox']").each(function (index) {
      var element = $(".dropdown_parent input[type='checkbox']")[index]
      if ($(element).is(":checked")) {
        count = count + 1
        if (count >= 3) {
          // disable remaining checkboxes
          $(".dropdown_parent input[type='checkbox']").each(function (index) {
            var element1 = $(".dropdown_parent input[type='checkbox']")[index]
            if ($(element1).is(":checked") == false) {
              $(element1).prop("disabled", true)
            }
          })
        }
        // console.log("==============", $(element).val(), count)
      }
    })
    if (count < 4) {
      // Enable all checkboxes
      $(".dropdown_parent input[type='checkbox']").each(function (index) {
        var element1 = $(".dropdown_parent input[type='checkbox']")[index]
        if ($(element1).is(":checked") == false) {
          $(element1).prop("disabled", false)
        }
      })
    }
  }

  manage_vehicle_new() {
    var count = 0
    $(".dropdown_parent input[type='checkbox']").each(function (index) {
      var element = $(".dropdown_parent input[type='checkbox']")[index]
      if ($(element).is(":checked")) {
        count = count + 1
        if (count >= 9) {
          // disable remaining checkboxes
          $(".dropdown_parent input[type='checkbox']").each(function (index) {
            var element1 = $(".dropdown_parent input[type='checkbox']")[index]
            if ($(element1).is(":checked") == false) {
              $(element1).prop("disabled", true)
            }
          })
        }
        // console.log("==============", $(element).val(), count)
      }
    })
    if (count < 10) {
      // Enable all checkboxes
      $(".dropdown_parent input[type='checkbox']").each(function (index) {
        var element1 = $(".dropdown_parent input[type='checkbox']")[index]
        if ($(element1).is(":checked") == false) {
          $(element1).prop("disabled", false)
        }
      })
    }
  }

  timeFormate1 = (time) => {
    if (time !== null && time !== "") {
      const timeString12hr = new Date(time)
      timeString12hr.setHours(timeString12hr.getHours() + 5);
      timeString12hr.setMinutes(timeString12hr.getMinutes() + 30)
      const text = timeString12hr.toLocaleTimeString({},
          { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' });
      return text
    } else {
      return 'NA'
    }
  }
  timeFormate= (time) => {
    if (time !== null && time !== "") {
      const timeString12hr = new Date(time)
        .toLocaleTimeString({},
          { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' }
        );
      return timeString12hr
    } else {
      return 'NA'
    }
  }

  timeFormate_indus= (time) => {
    if (time !== null && time !== "") {
      const timeString12hr = new Date(time)
        .toLocaleTimeString({},
          { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' }
        );
      return timeString12hr.replace("am","").replace("pm","").replace("AM","").replace("PM","")
    } else {
      return 'NA'
    }
  }

  getConvertedHour = (rtime) => {
    var hours = Math.floor(rtime)
    var minutes = Math.round(rtime * 60 % 60)
    if (hours <= 9) {
      hours = '0' + hours
    }
    if (minutes <= 9) {
      minutes = '0' + minutes
    }
    return hours + ":" + minutes
  }
  handleValidation = (start_location, end_location, start_date, end_date, start_time, end_time) => {

    let isValidate = true;

    if (!start_location) {
      isValidate = false
      $('#start_location').css({
        border: "1px solid red"
      })
    } else {
      $('#start_location').css({
        border: "none"
      })
    }

    if (!end_location) {
      isValidate = false;
      $('#end_location').css({
        border: "1px solid red"
      })
    } else {
      $('#end_location').css({
        border: "none"
      })
    }

    if (!start_date) {
      isValidate = false;
      $('#start_date').css({
        border: "1px solid red"
      })
    } else {
      $('#start_date').css({
        border: "none"
      })
    }

    if (!end_date) {
      isValidate = false;
      $('#end_date').css({
        border: "1px solid red"
      })
    } else {
      $('#end_date').css({
        border: "none"
      })
    }

    if (!end_time) {
      isValidate = false;
      $('#end_time').css({
        border: "1px solid red"
      })
    } else {
      $('#end_time').css({
        border: "none"
      })
    }

    if (!start_time) {
      isValidate = false;
      $('#start_time').css({
        border: "1px solid red"
      })
    } else {
      $('#start_time').css({
        border: "none"
      })
    }
    return isValidate
  }


  handleValidationDriver = (first_name, last_name, mobile_number, address) => {
    let isValidate = true;

    if (!first_name) {
      isValidate = false
      $('#first_name').css({
        border: "1px solid red"
      })
    } else {
      $('#first_name').css({
        border: "none"
      })
    }

    if (!last_name) {
      isValidate = false;
      $('#last_name').css({
        border: "1px solid red"
      })
    } else {
      $('#last_name').css({
        border: "none"
      })
    }

    if (!mobile_number) {
      isValidate = false;
      $('#mobile_no').css({
        border: "1px solid red"
      })
    } else {
      $('#mobile_no').css({
        border: "none"
      })
    }

    if (!address) {
      isValidate = false;
      $('#address').css({
        border: "1px solid red"
      })
    } else {
      $('#address').css({
        border: "none"
      })
    }
    return isValidate
  }

  tableCellWidth = (id) => {
    // console.log('id is', id)
    var divide = $('#' + id).find('th').length
    // console.log('divide',divide)
    var tableWidth = $('#' + id).outerWidth()
    // console.log('width', tableWidth)
    var cellWidth = tableWidth / divide
    $('#' + id).find('th').css({
      width: cellWidth,
    })
    $('#' + id).find('td').css({
      width: cellWidth,
    })
  }


  ServerDateTime = (rdate) => {
    if (rdate !== null) {
      var date = new Date(rdate);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      var convertDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
      // console.log('converted data is', convertDate)
      return convertDate
    } else {
      return 'NA'
    }
  }

  graphDateFormate = (rdate) => {
    if (rdate !== null) {
      var date = new Date(rdate);

      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"][month - 1]
      var day = date.getDate();
      const nth = (() => {
        const day = date.getDate();
        if (day >= 11 && day <= 13) {
          return "th"
        } else {
          switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
          }
        }
      })()
      if (day < 10) {
        day = '0' + day;
      }

      var convertDate = day + '-' + months + '-' + year
      return convertDate

    } else {
      return 'NA'
    }
  }

  downloadReportDate = (rdate) => {
    if (rdate !== null) {
      var date = new Date(rdate);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      var convertDate = day + '_' + month + '_' + year
      return convertDate
    } else {
      return 'NA'
    }
  }


  getParsedDate = (rdate) => {
    if (rdate !== null) {
      var date = new Date(rdate);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      var convertDate = year + '-' + month + '-' + day
      return convertDate
    } else {
      return 'NA'
    }
  }

  getTime = (rtime) => {
    // console.log(rtime)
    if (rtime !== null) {

      var time = new Date(rtime);

      var hours = time.getHours();
      var minutes = time.getMinutes();
      var seconds = time.getSeconds();
      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      var convertTime = hours + ':' + minutes + ':' + seconds
      return convertTime
    } else {
      return 'NA'
    }
  }

  convertMinToHours = (mins) => {
    var hours = (mins / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours < 9) {
      rhours = '0' + rhours
    }
    if (rminutes < 9) {
      rminutes = '0' + rminutes
    }
    var value = rhours + ' : ' + rminutes
    return value
  }

  convertMinToHoursText = (mins) => {
    var hours = (mins / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours < 9) {
      rhours = '0' + rhours
    }
    if (rminutes < 9) {
      rminutes = '0' + rminutes
    }
    var value = rhours + 'hrs ' + rminutes + 'min'
    return value
  }

  convertMinToHoursText1 = (sec) => {
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+'hrs '+minutes+'min '+seconds+"sec";
  }
  
  convertSecToMin = (sec) => {
    sec = Number(sec);
    var min = Math.floor(sec % 3600 / 60);
    // console.log('minutes', min)
    return min + " Mins"
  }
  convertMinToSec = (min) => {
    min = Number(min);
    var sec = Math.floor(min % 3600 / 60);
    // console.log('minutes', min)
    return sec
  }

  convertSecToHoursMin = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    // console.log('duration', d , h, m ,s)
    
    var hDisplay = h > 0 ? this.zeroAppend(h) : "00";
    var mDisplay = m > 0 ? this.zeroAppend(m) : "00";
    var sDisplay = s > 0 ? s : "00";
    // console.log(hDisplay,'............',mDisplay)
    return hDisplay + " hrs " + mDisplay + " mins" ;
  }
  convertSecToMinSec = (d) => {
    d = Number(d);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
  
    var mDisplay = m > 0 ? this.zeroAppend(m) : "00";
    var sDisplay = s > 0 ? s : "00";
    
    return mDisplay + " Mins " + sDisplay + " Secs";
  }
  convertSecToHoursMinNoText = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? this.zeroAppend(h) : "00";
    var mDisplay = m > 0 ? this.zeroAppend(m) : "00";
    var sDisplay = s > 0 ? s : "00";
    // console.log(hDisplay,'............',mDisplay)
    return hDisplay + ' : ' + mDisplay;
  }

  convertSecToHoursMinSec = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? this.zeroAppend(h) : "00";
    var mDisplay = m > 0 ? this.zeroAppend(m) : "00";
    var sDisplay = s > 0 ? s : "00";
    // console.log(hDisplay,'............',mDisplay)
    return hDisplay + ' Hr ' + mDisplay + ' Mins ' + sDisplay  + ' Secs ';
  }

  dateValidation(start, end) {
    var start = new Date(start);
    var end = new Date(end);  
    if (start > end) {
      return false
    } else {
      return true
    }
  }

  zeroAppend(value) {
    if (value <= 9) {
      return '0' + value
    } else {
      return value
    }
  }

  createDate = (date1, time1) => {
    var date = date1.toString()
    // console.log('date is',date)
    var onlyDate = date.substring(4, 15)
    var time = time1
    // console.log(date,'//////////',onlyDate,'date', time)
    if (time) {
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "pm" && hours < 12) hours = hours + 12;
      if (AMPM == "am" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
    }
    var convertedTime = sHours + ":" + sMinutes + ":00"
    var sendDate = new Date(onlyDate + ' ' + convertedTime)
    return sendDate
    // console.log('only date', sendDate)
    // console.log("date time", sendDate.toISOString())
  }

  rupeeFormat = (value) => {
    if (value !== undefined && value !== "" && value !== null) {
      var x = value.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers != '')
        lastThree = ',' + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return res
    } else {
      return "NA"
    }
  }

  createServerDateTime = (date, time) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if(month <= 9){
      month = '0'+month
    }
    if(day <= 9){
      day = '0'+day
    }
    var datetime = year + "-" + month + "-" + day + " " + time + ":" + "00"
    return datetime
  }

  returnNa = (value) => {
    if (value) {
      return value
    } else {
      return "NA"
    }
  }

  timePickerValue = (rdate) => {
    if (rdate !== null && rdate !== "") {
      var date = new Date(rdate);      
      var hours = this.zeroAppend(date.getHours());
      var minutes = this.zeroAppend(date.getMinutes());            
      var time = hours+':'+ minutes
      return time
    } else {
      return '00:00'
    }
  }
  
}

export default new CommonFunction()
