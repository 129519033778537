import React , { Component } from 'react';
import { Link , Redirect } from 'react-router-dom';
import $ from 'jquery';
import './map.css'

class MapPage extends Component{
  constructor(props){
    super(props)

    const token = localStorage.getItem("token")

        let loggedIn = true
        if(token == null){
          loggedIn = false
        }

        this.state = {
          loggedIn
        }


    console.log(">>>>>>>>>>>>>>>>>>>> ")
    console.log(props);
    //test();
    $(document).ready(function(){
       console.log(props,"SHAKIL");
       console.log("Map page loaded");
       var urlParams = new URLSearchParams(document.location.search)
       // console.log(window.location.href)
       var main_url = window.location.href
       // console.log(window.location.href.split('?id='))
       main_url = window.location.href.split('?id=')[0]
       // var state = window.location.href.split('?id=')[1]
       var flag = urlParams.get("id");
       var tracker = urlParams.get("data");
       var vname = urlParams.get("vname");
       console.log(flag)
       if (flag=="true")
       {
         flag = false
         window.location.href = main_url+"?id=false&data="+tracker+"&vname="+vname;
         // console.log(window.location.href)
       }
      else{
        console.log("***")
      }


    });
  }

   scriptLoaded() {
	 // alert("script is being loaded ..");
   var urlParams = new URLSearchParams(document.location.search);
    var tracker = urlParams.get("data");
    var vname = urlParams.get("vname");
	 let srno_test = tracker;//"3933892"
    let backend_host = "10.200.231.57:8000"//window.location.host;
   //let backend_host = "localhost:8000"//window.location.host;
  //  let backend_host = "lenderdbbackend01.do-blr.mpgpsdc.com"//window.location.host;

   // srno_test = 3933892;
   let user = 226;
	 //window.liveTrackInit( srno_test );
	 window.liveInit( srno_test,  user, vname ,backend_host);
   }

  loadUrl = (url) =>{
  	const script = document.createElement("script");
  	script.src = url;
  	//script.async = true;
  	script.async = false;
	//script.onload = () =>this.scriptLoaded()
  	document.body.appendChild(script);
	return script

  }
  componentDidMount() {
  let a_script = this.loadUrl( "js_files/map.js" )
	a_script.onload = () =>this.scriptLoaded()
	this.loadUrl( "js_files/PruneCluster.js" )
  this.loadUrl( "js_files/markerclusterer.js" )
	this.loadUrl( "js_files/styles.js" )
	this.loadUrl( "js_files/mp_lib.js" )
	this.loadUrl( "js_files/mapRotate.js" )
	this.loadUrl( "js_files/mpgps.js" )
	this.loadUrl( "js_files/mmi.js" )
  this.loadUrl( "js_files/livetrack.js" )
  this.loadUrl("//cdnjs.cloudflare.com/ajax/libs/moment.js/2.10.3/moment.min.js")

  }

  render(){
    if(this.state.loggedIn === false){
        return <Redirect to="/" />
      }
    return(
      <div>
      <div id="map">
      </div>
      <div className="m-footer-div">
      	<div className="row">
      		<div className="col">
      			<div>
      				<h5 style={{fontWeight: 'bold',fontSize:'14px'}}>Live tracking for</h5>
      	dfgh
	       </div>
      			<div>
      				<h5 style={{color: '#ec7011'}}>Vehicle Name</h5>
      			</div>
      		</div>
      		<div className="col" style={{paddingTop: '6px'}}>
      			<i className="fas fa-chevron-down" style={{float: 'right'}}  id="div-down"></i>
      			<i className="fas fa-chevron-up" style={{display: 'none',float:'right'}} id="div-up"></i>
      		</div>
      	</div>
      	<div className="row">
      		<div className="col">
      			<div>
      				<h5>Now Showing :</h5>
      			</div>
      			<div>
      				<h5>2 Jan 17 11:55 PM</h5>
      			</div>
      		</div>
      	</div>
      	<div className="row">
      		<div className="col">
      			<h5 style={{lineHeight: '1.4em'}}>Acme Regency Apartments, General Arunkumar Vaidya Marg, Indira Nagar, Vile Parle West, Mumbai, Maharashtra 400056, India</h5>
      		</div>
      	</div>
      </div>


      <div id="floating-btn">
      	<button className="btn float-btn">
          <img src={require('./assets/images/Home-Circle-plus-icon.png')} id="btn-plus" style={{display: 'none',marginLeft:'-8px'}} className="toggle-down"/>
          <img src={require('./assets/images/Home-Circle-cross-icon.png')} id="btn-close" style={{marginLeft:'-8px'}}/>
      	</button>
      </div>



      <div id="wel-div">
        <div className="">
          <div className="act-opt">
            <img src={require('./assets/images/Live-Track-Pin.png')} style={{display:'inline'}}/>
            <h4 style={{display:'inline',marginLeft:'5px',fontSize:'13px',color:'#fff'}}>Live Track</h4>
          </div>
        </div>
        <div style={{marginTop:"-9px"}}>
      <input type="hidden" id="rp_srno" name="sel_srno" value="" />
      <div id = "lt-vehicle-detail">
        <div style={{padding:'.5em',backgroundColor:'#ffffff'}}>
          <div className="detail-div-new">
            <div style={{display:'inline-block',width:'20%',textAlign:'center'}}>
              <img src={require('./assets/images/vehicle_o.png')}/>
            </div>
            <div style={{display:'inline-block',width:'76%'}}>
              <h5 id='trv_car_name' style={{ fontSize:'13px'}}>Loading.....</h5>
            </div>
          </div>
          <div className="detail-div-new">
            <div style={{display:'inline-block',width:'20%',textAlign:'center'}}>
              <img src={require('./assets/images/watch.png')}/>

            </div>
            <div style={{display:'inline-block',width:'38%'}}>
              <h5 id = "trv_car_time" style={{ fontSize:'13px'}}>Loading.....</h5>
            </div>
            <div style={{display:'inline-block',width:'37%',textAlign:'center'}} id = "trv_date">
              <h5 id="trv_car_date" style={{ fontSize:'13px'}}><strong>Loading.....</strong></h5>
            </div>
          </div>
            <div className="detail-div-new location-new">
              <div style={{display:'inline-block',width:'20%',textAlign:'center'}} className="add-img-div">
                <img src={require('./assets/images/Track-My-Location-Orange.png')}/>

              </div>
              <div style={{display:'inline-block',width:'76%'}} className="address-div">
                <h5 style={{lineHeight:'1.4em'}} id='trv_addr' style={{ fontSize:'13px'}}>Loading.....</h5>
              </div>
            </div>
          </div>
        </div>
      <div id="connection-lost" style={{display:'none',background:'white',paddingBottom:'20px'}}>
        <div className="conn-car-detail">
          <div className="col-sm-12" style={{paddingLeft:'0px',background:'white',marginBottom:'13px',color:'red',background:'#ed2b40!important'}}>
            <h5>  </h5>
            <p style={{textAlign:'center',fontSize:'13px',color:'#fff',paddingTop:'10px',paddingBottom:'10px'}}>Connection Lost</p>
          </div>
        </div>
        <div className="" style={{paddingLeft:'1em',color:'#777777'}}>
          <h4 style={{lineHeight:'1.5em',fontSize:'12px'}}>
            Apologies, something went wrong<br/> we are trying hard to establish the data connection.
          </h4>
        </div>
        <div className="" style={{paddingLeft:'1em',color:'#777777'}}>
          <h4 style={{lineHeight:'1.5em',fontSize:'12px'}}>
                If you are still getting this  message,<br/>please call us on our helpline number<br/><br/>
                <span style={{color:'#ec701a',fontSize:'14px'}}>1800 266 4800( 24 x7)</span>
                <span style={{color:'red'}}><br/>
                Last Reporting Time : <span id="last_rpt_time_id"></span></span><br/>
                <span style={{color:'red'}}>Address : <br/><span id="con_lost_add"></span></span>

          </h4>
        </div>
      </div>
      <div id="satellite_lost_div" style={{display:'none',background:'white',border:'1px solid transparent',textAlign:'center'}}>
              <div className="" style={{background:'white'}}>
                      <div className='' style={{fontSize:'11px',fontWeight:'bolder',paddingTop:'30px'}}>
                                      Last Reporting Time : <span id="satellite-lost-datetime-val" style={{color:'#f68145'}}></span>
                                      Address : <span id="satellite-lost-add"></span>
                      </div>
                      <div className="sat-imag-div">
                              <img src={require('./assets/images/sat_no.png')} alt="no-satellite-connection" style={{height:'116px'}}/>

                      </div>
                      <div className="sat-txt-div1" style={{fontSize:'14px'}} >
                              This may have occurred due to vehicle parked in low
                                satellite connectivity area or basement parking.
                      </div>
                      <div className="sat-txt-div2" style={{fontSize:'14px'}}>
                              You will be able to track your vehicle once it is in a better satellite
                              connectivity area
                      </div>
                      <div className="sat-txt-div1" style={{paddingBottom:'30px',fontSize:'14px'}}>
                              PLEASE CALL FOR ANY QUERIES on, 1800 266 4800
                                  </div>
                          </div>
                  </div>

                  </div>
                  <div id="marker-info">
                  <div style={{display:'inline-block',marginRight:'2px',marginLeft:'0px'}} className="ongoing-m">
                    <img src={require('./assets/images/Ongoing-Marker.png')} alt="moving"/>

                  </div>
                  <div style={{display:'inline-block'}} className="parked-m">
                  <img src={require('./assets/images/Parked-Marker.png')} alt="moving"/>

                  </div>
                  <div style={{display:'inline-block'}} className="idel-m">
                    <img src={require('./assets/images/Idle-Marker.png')} alt="idel"/>

                  </div>
                  <div style={{display:'inline-block',marginLeft:'0px'}} className="lost-conn-m">
                    <img src={require('./assets/images/Connection-Lost-Marker.png')} alt="lost"/>

                  </div>
                  </div>

                  <div style={{position:'relative'}}>
                  <div id="tool-tip-msg" style={{display:'none'}}>
                    <h5>Connection lost</h5>
                  </div>
                  </div>
                  </div>


      </div>
    )
  }
}

export default MapPage
