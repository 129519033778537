import React, { Component } from 'react';
import logo from './logo.svg';
import Header from './components/Header';
import Forms from './components/Forms';
import { BrowserRouter, Route, Switch , Redirect} from 'react-router-dom';
import Dashboardtable from './components/Dashboardtable';
import Dashboardtable_demo from './components/Dashboardtable_demo';
import Today_inst from './components/Today_inst'
import Login from './Login';
import MapPage from './Map';
import Home from "./Home";
import 'bootstrap/dist/css/bootstrap.css';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Lendernotfound from './components/Lendernotfound';
import $ from 'jquery';
import Common_function from './components/Common_function';
import { osName,osVersion,browserName,deviceType } from 'react-device-detect';
import ApiUrlCall from './components/Api/apiurl';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
import Api_integration from './components/Api_integration';
import { sha256 } from 'js-sha256';

class App extends Component {
  constructor(props) {
    super(props);
    this.otSession = React.createRef();
    this.switch = React.createRef()
    this.state = {
      toogleShare: false,
      shareContent: '',
      text: "Turn On Screen Sharing",
      checkedA: false,
      apiKey: '',
      tokenID: '',
      sessionID: '',
      message: '',
      rating: 0,
      user :'',
      showfeedback: false,
    }
    this.publisherEventHandlers = {
      streamCreated: event => {
        console.log('event', event)
        var connection_id = event.target.session.connection.id
        this.AcknowledgementSession(1, connection_id)
      },
      streamDestroyed: event => {
        $('#ot_dialog').hide()
        if (this.state.showfeedback) {
          $('#feedback_dialog').slideDown('slow')
        }
        this.setState({
          toogleShare: false,
          shareContent: '',
          text: 'Turn ON Screen Sharing'
        })
        console.log('Publisher stream destroyed!');
      },
      accessAllowed: event => {
        $('#ot_dialog').show()
      },
      accessDenied: event => {
        var connection_id = ""
        this.AcknowledgementSession(0, connection_id)
        this.setState({
          toogleShare: false,
          shareContent: '',
          text: 'Turn ON Screen Sharing'
        })
      }
    };

    this.sessionEventHandlers = {
      signal: (event) => {
        this.setState({
          message: "Product Expert Connected",
          showfeedback: true,
        })
        setTimeout(() => {
          $('#ot_dialog').hide()
        }, 1500);
      },
    }
  }
  openTokConnect = () => {
    const { apiKey, sessionID, tokenID } = this.state
    if (apiKey && sessionID && tokenID) {
      var content = (() => {
        return (
          <OTSession
            ref={this.otSession}
            apiKey={apiKey}
            sessionId={sessionID}
            token={tokenID}
            option={{ connectionEventsSuppressed: true }}
            onConnnect={() => { console.log('connected successfully') }}
            eventHandlers={this.sessionEventHandlers}

            signal={
              {
                data: 'SESSION',
                type: 'CSA_JOINED_SESSION'

              }
            }
          >
            <OTPublisher
              properties={{
                videoSource: 'screen',
                width: 0, height: 0,
              }}
              eventHandlers={this.publisherEventHandlers}
            />
            <OTStreams>
              <OTSubscriber />
            </OTStreams>
          </OTSession>
        )
      })()
      this.setState({
        shareContent: content,
      }, () => {
        this.setState({
          toogleShare: true,
          message: 'Waiting for the Product Expert to connect',
          text: 'Turn OFF Screen Sharing'

        }, () => {
          this.startHide()
        })
      })
    }
  }

  openTokDisconnect = () => {
    console.log('session is', this.otSession.current.sessionHelper.session.currentState)
    this.otSession.current.sessionHelper.session.disconnect()
    this.setState({
      toogleShare: false,
      shareContent: '',
      text: 'Turn ON Screen Sharing'
    })
  }

  AcknowledgementSession = (choice, id) => {
    var data = { session_id: this.state.sessionID, choice: choice, connection_id: id }
    console.log('data is', data)
    ApiUrlCall.openTokAck(JSON.stringify(data)).then(response => {
      console.log('response of ack is', response)
    }).catch(error => {
      console.log('error while sending acknowledgment to server', error)
    })
  }

  screenShare = (e) => {

    if (e.target.checked) {
      ApiUrlCall.openTokSession().then(response => {
        if (response.data.response_code && response.data.is_success) {
          this.setState({
            apiKey: response.data.data.api_key,
            sessionID: response.data.data.session_id,
            tokenID: response.data.data.token_id
          }, () => {
            this.openTokConnect()
          })
        }
      }).catch(error => {
        console.log('error while fetching opentok session', error)
      })
    } else {
      this.openTokDisconnect()
    }
  }
   
  slideUp = (e) => {
    $('.fixed-toggle').slideUp()
    $(e.currentTarget).next().show(200)
    $(e.currentTarget).hide()
    $('.icon-div').css({
      top: "0"
    })
  }

  slideDown = (e) => {
    $('.fixed-toggle').slideDown(200)
    $(e.currentTarget).prev().show()
    $(e.currentTarget).hide()
    $('.icon-div').css({
      top: "45px"
    })
  }

  startHide = (e) => {
    $('.fixed-toggle').slideUp(200)
    $('.fa-chevron-down').show()
    $(".fa-chevron-up").hide()
    $('.icon-div').css({
      top: "0"
    })
  }


  render(){


    
    $(window).on("blur focus", function(e) {
      var prevType = $(this).data("prevType");  
      if (prevType != e.type && window.location.pathname!= "/" ) {   //  reduce double fire issues
          switch (e.type) {
              case "blur":
                  localStorage.setItem('cache blur date',new Date())
                  var user_id = localStorage.getItem("user")
                  var sha256 = require('js-sha256');
                 var userid_enc = sha256(user_id);
             
                  var active_session = {
                    start_time : Common_function.ServerDateTime(localStorage.getItem("cache focus date")),
                    end_time: Common_function.ServerDateTime(localStorage.getItem("cache blur date"))
                  }

                  var device_detail = [{
               //  user_id:user,
                   event_id : 1,
                  unique_id : userid_enc,
                  // user_id: user,
                   // unique_id:"asfhgfhjyhfrytnnkweuygdbvagsssshnuiygy",   
                   
                  
                    os_version : osVersion,
                    model_name: osName,
                    value : active_session,user_id:user_id,
                  
                  }]

                  const analytics_details = {
                    data : device_detail , source : 2
                  };

                  
                 JSON.stringify(analytics_details)

                //  console.log("hello..", analytics_details)
                        
                  ApiUrlCall.send_analytics(analytics_details).then(response => {
       
                    if (response.status === 200) {
                      return

                    }
                  }
                  )
                  break;
                  
              case "focus":
                  //console.log("focus",new Date())
                  localStorage.setItem('cache focus date',new Date())

                  break;
          }
      }
      $(this).data("prevType", e.type);
    })


    

    return(
      <BrowserRouter>
      <Switch>
      <Route path="/" exact component={Login} />
      <Redirect exact from="/home" to="/home/Install" />
      <Route exact path="/home/:page?" render={props => <Home {...props} />} />
      {/* <Route path="/dashboard" exact component={Dashboardtable} /> */}
      {/* <Route path="/lenderdashboard" exact component={Dashboardtable_demo} /> */}
      <Route path="/map" exact component={MapPage}  />
      <Route path="/lendernotfound" exact component={Lendernotfound}  />
      {/* <Mobile_Login></Mobile_Login> */}
     
       <Route path="/Api_integration" component={Api_integration} /> 
         

      </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
